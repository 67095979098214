'use client';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type Settings = {
  currency: 'ETH' | 'USD';
};

type TSettingsContext = [Settings, (v: Partial<Settings>) => void];
const SettingsContext = createContext<TSettingsContext>([
  {
    currency: 'ETH',
  },
  () => null,
]);

const defaultSettings: Settings = {
  currency: 'ETH',
};

const get = (key: string, fallback: string): string => {
  if (typeof window === 'undefined') {
    return fallback;
  }
  return window.localStorage.getItem(`floor.${key}`) || fallback;
};
const set = (key: string, value: string) => {
  if (typeof window === 'undefined') {
    return;
  }
  window.localStorage.setItem(`floor.${key}`, value);
};

const getSettings = (): Settings => {
  return Object.entries(defaultSettings).reduce(
    (acc, [key, fallback]) => {
      return {
        ...acc,
        [key]: get(key, fallback),
      };
    },
    {} as unknown as Settings,
  );
};

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [settings, setSettings] = useState<Settings>(defaultSettings);
  useEffect(() => {
    setSettings(getSettings());
  }, []);

  // Merge any new setting values into the existing ones
  const updateSettings = useCallback((value: Partial<Settings>) => {
    setSettings((prev) => ({ ...prev, ...value }));
  }, []);

  // Write all settings to storage
  useEffect(() => {
    Object.entries(settings).forEach(([key, stateValue]) => {
      const storedValue = get(key, defaultSettings[key as keyof Settings]);
      if (stateValue !== storedValue) {
        set(key, stateValue);
      }
    });
  }, [settings]);

  const value = useMemo(
    () => [settings, updateSettings] as TSettingsContext,
    [settings, updateSettings],
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);

/**
 * Generic function to query a subgraph and return the data response
 */
const querySubgraph = async <T = Record<string, never>>({
  url,
  query,
}: {
  url: string;
  query: string;
}): Promise<T> => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
      }),
    });

    if (response.ok) {
      const { data } = await response.json();

      return data;
    } else {
      throw new Error(`Failed to fetch ${url} with query ${query}`);
    }
  } catch (err: any) {
    throw new Error(err);
  }
};

export default querySubgraph;

import { ArrowPathIcon } from '@heroicons/react/24/outline';
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ForwardedRef,
  forwardRef,
  useMemo,
} from 'react';

type ButtonElementProps = ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorElementProps = AnchorHTMLAttributes<HTMLAnchorElement>;

export interface ButtonProps {
  className?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  size?: 'small' | 'base';
  disabled?: boolean;
  processing?: boolean;
}

export type Props = (AnchorElementProps | ButtonElementProps) & ButtonProps;

const BASE_STYLES =
  'rounded-lg flex items-center justify-center focus:ring-4 focus:outline-none transition-colors border relative';

const variantMap = {
  primary: `border-brand-blue bg-gradient-to-b from-brand-blue/0 to-brand-blue/20 hover:from-brand-blue/20 hover:to-brand-blue/20 focus:ring-brand-blue/20`,
  secondary: `border-gray-200 hover:bg-gray-200/20 focus:ring-gray-200/20`,
  tertiary: `border-brand-red bg-gradient-to-b from-brand-red/0 to-brand-red/20 hover:from-brand-red/20 hover:to-brand-red/20 focus:ring-brand-red/30`,
};

const sizeMap = {
  base: `px-8 py-3 text-sm font-bold`,
  small: 'py-2 px-2 text-xs font-medium',
};

export const Button = forwardRef(function Button(
  {
    variant = 'primary',
    size = 'base',
    children,
    className = '',
    disabled,
    processing,
    ...props
  }: Props,
  ref: ForwardedRef<any>,
) {
  const styles = useMemo(
    () =>
      `text-white ${BASE_STYLES} ${className} ${variantMap[variant]} ${
        sizeMap[size]
      } ${processing ? 'cursor-not-allowed text-opacity-20' : ''} ${
        disabled ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'
      }`,
    [className, variant, size, processing, disabled],
  );

  if ('href' in props) {
    return (
      <a {...(props as AnchorElementProps)} ref={ref} className={`${styles}`}>
        {children}
      </a>
    );
  } else {
    return (
      <button
        type="button"
        disabled={disabled || processing}
        {...(props as ButtonElementProps)}
        ref={ref}
        className={styles}
      >
        {children}
        {processing && (
          <span className="absolute inset-0 flex items-center justify-center">
            <ArrowPathIcon className="animate-spin h-5 w-5 text-opacity-100 text-white" />
          </span>
        )}
      </button>
    );
  }
});

export default Button;

import { sepolia, mainnet, Chain } from 'viem/chains';
import { useAccount } from 'wagmi';

const chains = [sepolia, mainnet];
export const useChain = () => {
  const { chain } = useAccount();

  return (chain ||
    chains.find(
      (c) => c.id === Number(process.env.NEXT_PUBLIC_CHAIN_ID),
    )) as Chain;
};

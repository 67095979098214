import { Address } from 'viem';
import { mainnet, sepolia } from 'wagmi/chains';

export const Addresses = {
  [sepolia.id]: <
    {
      [key: string]: Address;
    }
  >{
    // used by the app
    Multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
    UniswapQuoter: '0xEd1f6473345F45b75F8179591dd5bA1888cf2FB3',
    FloorDeployer: '0xebc79bDd526a9e607DA2Cf169e99bE3e4b918537',
    OldTokenFloor: '0x8edEF7f24344a3323209fa92A886B838300F5605',
    OldTokenAFloor: '0x6C1BEB930BC174Fba5bd3afa61536b4f859338b9',
    OldTokenSFloor: '0xC49c96003D3CbC05d508D34Fd3418BF05c253C39',
    OldTokenGFloor: '0x5A76dBc57AbdE4039eC4b2889A628E8D517b85D5',
    WETH: '0xfff9976782d46cc05630d1f6ebab18b2324d6b14',
    DAI: '0x3e622317f8C93f7328350cF0B56d9eD4C620C5d6',
    stETH: '0x1427bbe2429c6b15305243bf8dc12e4362d449fd',
    USDC: '0xca6c31cc71fb8f00dc2c465299f605039e0e1f4b',
    Drip: '0x2D4632c16fA7132555E31AcFAd2468663AAeAb26',
    // from deployments
    AuthorityRegistry: '0xa864ecf7751348592307a089468a429c5dc7d693',
    AuthorityControl: '0x2df7427f661b8e06f0364898db0fce3cdb10f5a9',
    FloorToken: '0x3b0fccbd5dae0570a70f1fb6d8d666a33c89d71e',
    MigrateFloorToken: '0xf94b9df11f26175bbfe6e3b0b61fbbafb6f15f4b',
    FloorV1TokenMerkleClaim: '0x074D749B3308F5527D7b0F483239043Bc3882e12',
    CollectionRegistry: '0xd609ba5d63915652c6642695e9c50b68e2ede0e5',
    Multisig: '0xA9d93A5cCa9c98512C8C56547866b1db09090326',
    Treasury: '0x3b91f74ae890dc97bb83e7b8edd36d8296902d68',
    DistributedRevenueStakingStrategy:
      '0x183549c6d81818fc80b4cd1194f2712b3efe1a15',
    NFTXInventoryStakingStrategy: '0xdcc3f4e4e229269dce3c678aaf90c2d6b23008fd',
    NFTXLiquidityPoolStakingStrategy:
      '0x19f034694643200ae222c0380a068083c09fbc31',
    RevenueStakingStrategy: '0x7514b1b811e03ec1e969c910ab6562895a3f2cad',
    UniswapV3Strategy: '0x5091a094f72e047df66a1de37857377d4ca9aaa4',
    StrategyFactory: '0xdf2e023ea56d752d0b5be79f65557987976676cc',
    StrategyRegistry: '0x156e9b71dcaa660d3ec849dc93b54a3a7f82f23e',
    VeFloorStaking: '0x2b7e22610db92169b87a27df1929c9315b59509f',
    NewCollectionWars: '0x72d6c121d0cdd773da8ba75057c5f4f2ef8755a1',
    SweepWars: '0x09c9381417e0ecff536ec33375f1d5b2efa97d78',
    EpochManager: '0xdbcf0946e3573661fe2696ef06a766a06da92518',
    RegisterSweepTrigger: '0xdf95bd23dc39749e870b86b90203b94de5d0971a',
    StoreEpochCollectionVotesTrigger:
      '0xa56de1ec6c8ede73d7fb1eeb7dd3cc209cd655b5',
    LiquidateNegativeCollectionManualTrigger:
      '0xfe702e86ff008cd21068ddd9f50dcb64eec1d2e7',
    VestingClaim: '0x89336e8a7e6fb2a0ad0803e940958718c5bf44be',
    ManualSweeper: '0x73ea878861657d7d1e94ff0716834d48f80b2f8d',
    SudoswapSweeper: '0x951670cc332a4c56d9f03f5d14f78f7c5365d838',
    NFTXV3Strategy: '0x669a198c04dbef381b9c20b31fc4cb1f684364be',
    NFTXV3LiquidityStrategy: '0x7e90bef7ea020fa77e2ceb03cc858ce653c3e0a8',
  },
  [mainnet.id]: <
    {
      [key: string]: Address;
    }
  >{
    // used by app
    Multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
    UniswapQuoter: '0x0209c4dc18b2a1439fd2427e34e7cf3c6b91cfb9',
    FloorDeployer: '0x81B14b278081e2052Dcd3C430b4d13efA1BC392D',
    OldTokenFloor: '0xf59257E961883636290411c11ec5Ae622d19455e',
    OldTokenAFloor: '0x0C3983165E9BcE0a9Bb43184CC4eEBb26dce48fA',
    OldTokenSFloor: '0x164afe96912099543bc2c48bb9358a095db8e784',
    OldTokenGFloor: '0xb1cc59fc717b8d4783d41f952725177298b5619d',
    USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    stETH: '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
    DAI: '0x6b175474e89094c44da98b954eedeac495271d0f',
    WETH: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    Drip: '0x0',
    // from deploymemnts
    AuthorityRegistry: '0xa864ecf7751348592307a089468a429c5dc7d693',
    AuthorityControl: '0x2df7427f661b8e06f0364898db0fce3cdb10f5a9',
    FloorToken: '0x3b0fccbd5dae0570a70f1fb6d8d666a33c89d71e',
    MigrateFloorToken: '0xf94b9df11f26175bbfe6e3b0b61fbbafb6f15f4b',
    FloorV1TokenMerkleClaim: '0x074D749B3308F5527D7b0F483239043Bc3882e12',
    CollectionRegistry: '0xd609ba5d63915652c6642695e9c50b68e2ede0e5',
    Multisig: '0xA9d93A5cCa9c98512C8C56547866b1db09090326',
    Treasury: '0x3b91f74ae890dc97bb83e7b8edd36d8296902d68',
    DistributedRevenueStakingStrategy:
      '0x183549c6d81818fc80b4cd1194f2712b3efe1a15',
    NFTXInventoryStakingStrategy: '0xdcc3f4e4e229269dce3c678aaf90c2d6b23008fd',
    NFTXLiquidityPoolStakingStrategy:
      '0x19f034694643200ae222c0380a068083c09fbc31',
    RevenueStakingStrategy: '0x7514b1b811e03ec1e969c910ab6562895a3f2cad',
    UniswapV3Strategy: '0x5091a094f72e047df66a1de37857377d4ca9aaa4',
    StrategyFactory: '0xdf2e023ea56d752d0b5be79f65557987976676cc',
    StrategyRegistry: '0x156e9b71dcaa660d3ec849dc93b54a3a7f82f23e',
    VeFloorStaking: '0x2b7e22610db92169b87a27df1929c9315b59509f',
    NewCollectionWars: '0x72d6c121d0cdd773da8ba75057c5f4f2ef8755a1',
    SweepWars: '0x09c9381417e0ecff536ec33375f1d5b2efa97d78',
    EpochManager: '0xdbcf0946e3573661fe2696ef06a766a06da92518',
    RegisterSweepTrigger: '0xdf95bd23dc39749e870b86b90203b94de5d0971a',
    StoreEpochCollectionVotesTrigger:
      '0xa56de1ec6c8ede73d7fb1eeb7dd3cc209cd655b5',
    LiquidateNegativeCollectionManualTrigger:
      '0xfe702e86ff008cd21068ddd9f50dcb64eec1d2e7',
    VestingClaim: '0x89336e8a7e6fb2a0ad0803e940958718c5bf44be',
    ManualSweeper: '0x73ea878861657d7d1e94ff0716834d48f80b2f8d',
    SudoswapSweeper: '0x951670cc332a4c56d9f03f5d14f78f7c5365d838',
    NFTXV3Strategy: '0x669a198c04dbef381b9c20b31fc4cb1f684364be',
    NFTXV3LiquidityStrategy: '0x7e90bef7ea020fa77e2ceb03cc858ce653c3e0a8',
  },
} as const;

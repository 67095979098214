import { Chain } from 'viem';
import { mainnet } from 'viem/chains';

type ChainObject = { [key in Chain['id']]?: any };

// Overload for when `key` is provided
export function getChainConstant<
  T extends ChainObject,
  K extends keyof T[keyof T],
>(obj: T, chainId: Chain['id'] | undefined, key: K): T[keyof T][K];

// Overload for when `key` is not provided
export function getChainConstant<T extends ChainObject>(
  obj: T,
  chainId?: Chain['id'],
): T[keyof T];

// Implementation
export function getChainConstant<
  T extends ChainObject,
  K extends keyof T[keyof T],
>(obj: T, chainId?: Chain['id'], key?: K) {
  // Early return for the case where key is not provided
  if (!key) {
    return obj[chainId || mainnet.id];
  }

  // Handle case where both chainId and key are provided
  if (chainId) {
    return obj?.[chainId]?.[key] || obj[mainnet.id][key];
  }

  // Fallback for when only key is provided
  return obj[mainnet.id][key];
}
